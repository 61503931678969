:root { --brand-font: 'Apercu Aktiv', Helvetica, Arial, sans-serif; }

//TODO: to link fonts from google cdn when we have it
@font-face {
  font-family: "Apercu Aktiv";
  src: url("/assets/fonts/ApercuAktiv-Regular.woff") format("woff2"),
       url("/assets/fonts/ApercuAktiv-Regular.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Aktiv";
  src: url("/assets/fonts/ApercuAktiv-Italic.woff") format("woff2"),
       url("/assets/fonts/ApercuAktiv-Italic.woff2") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Apercu Aktiv";
  src: url("/assets/fonts/ApercuAktiv-Bold.woff") format("woff2"),
       url("/assets/fonts/ApercuAktiv-Bold.woff2") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Aktiv";
  src: url("/assets/fonts/ApercuAktiv-BoldItalic.woff") format("woff2"),
       url("/assets/fonts/ApercuAktiv-BoldItalic.woff2") format("woff");
  font-weight: 700;
  font-style: italic;
}
