@import '~react-dropdown/style.css';

.Dropdown-control {
  border-radius: var(--brand-border-radius);
  border-color: var(--brand-natural-grey);
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  padding: 8px 32px 8px 10px;
}

.Dropdown-arrow {
  top: 11px;
  width: 12px;
  height: 8px;
  border: none;
  background-image: url('/assets/icons/chevron_bottom.svg');
}

.Dropdown-menu {
  top: calc(100% + 8px);
  border-radius: var(--brand-border-radius);
  border-color: var(--brand-natural-grey);
}

.Dropdown-option {
  font-size: 15px;
  line-height: 15px;

  &.is-selected {
    background: var(--brand-midnight);
    color: var(--brand-white);

    &:hover {
      background: var(--brand-midnight);
    }
  }

  &:hover {
    background: transparent;
  }
}
