.form {
  &__error {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: var(--color-error);
  }
}
