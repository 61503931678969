:root {
  --brand-white:          #FFFFFF;
  --brand-white--natural:   #{rgba(#FFFFFF, 0.95)};
  --brand-light-grey:     #F9F9F9;
  --brand-natural-grey:   #E7EAEE;
  --brand-grey:           #8C9BB0;
  --brand-midnight:       #191E24;
  --brand-light-midnight: #2E3742;
  --brand-dark-midnight:  #0A0D0F;
  --brand-black:          #000000;

  --brand-blue:       #337BE2;
  --brand-light-blue: #A1D2FF;
  --brand-red:        #E25061;
  --brand-green:      #2EBA88;
  --brand-orange:     #FB7D36;
  --brand-purple:     #A96FF3;
}
