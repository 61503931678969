.radio {
  --border-color: var(--brand-natural-grey);
  --background: var(--brand-light-grey);
  --selected-color: var(--brand-blue);
}

.radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  color: var(--brand-light-midnight);

  $self: &;

  &__input {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked + #{$self}__styled {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__styled {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1.5px solid var(--border-color);
    border-radius: 100%;
    background: var(--background);
    margin-right: 8px;

    &:after {
      content: '';
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--selected-color);
    }
  }

  &__label {
    display: flex;
    justify-content: flex-end;
    line-height: 16px;
  }
}
