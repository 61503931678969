.button {
  --padding: 12px;
  --background: var(--brand-blue);
  --background--hover: #1C61C4;
  --font-size: var(--body-font-size);
  --line-height: 24px;
  --letter-spacing: (--body-letter-spacing);
  --color: var(--brand-white--natural);
  --border-radius: var(--brand-border-radius);

  &--secondary {
    --background: var(--brand-light-grey);
    --background--hover: var(--brand-natural-grey);
    --color: var(--brand-blue);
  }

  &--negative {
    --background: var(--brand-light-grey);
    --background--hover: var(--brand-natural-grey);
    --color: var(--brand-red);
  }

  &--small {
    --padding: 6px 8px;
    --font-size: var(--heading-6-font-size);
    --line-height: var(--heading-6-line-height);
  }

  &--large {
    --padding: 18px;
    --font-size: var(--heading-5-font-size);
    --line-height: var(--heading-5-line-height);
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--padding);
  background: var(--background);
  font-family: var(--font);
  font-size: var(--font-size);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  text-align: center;
  color: var(--color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--brand-transition);
  transition-property: background, opacity;;

  &:hover {
    background: var(--background--hover);
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--wide {
    width: 100%;
  }
}
