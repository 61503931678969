.choice {
  --margin-bottom: 24px;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline;
  margin: 0 0 var(--margin-bottom);
  padding: 0;
  width: 100%;
  font-family: var(--font-family);
  text-align: left;

  &:last-child {
    --margin-bottom: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &__icon {
    float: right;
  }
}
