.scroll-top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  background: var(--brand-midnight);
  color: var(--brand-white);
  border-radius: var(--brand-border-radius);
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
