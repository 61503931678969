.hide-svg {
  svg {
    display: none !important;
  }
}

.employees-list {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.pagination-controls {
  --border-color: var(--brand-natural-grey);
  --color: var(--brand-grey);
  --active-background: var(--brand-midnight);
  --active-color: var(--brand-white);
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.pagination-controls {
  list-style-type: none;
  display:flex;
  margin: 0;
  padding: 0;

  $self: &;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border: 1px solid var(--border-color);
    font-size: 12px;
    font-weight: 700;
    color: var(--color);
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &--active {
      background: var(--active-background);
      color: var(--active-color)
    }

    svg {
      width: 7px;
    }
  }

  &__button-number {
    padding-top: 2px;
  }

  &__page {
    &:first-child {
      #{$self}__button {
        border-top-left-radius: var(--brand-border-radius);
        border-bottom-left-radius: var(--brand-border-radius);
      }
    }

    &:last-child {
      #{$self}__button {
        border-top-right-radius: var(--brand-border-radius);
        border-bottom-right-radius: var(--brand-border-radius);
      }
    }
  }
}

.subText {
  font-size: var(--sub-font-size);
  line-height: var(--sub-line-height);
  letter-spacing: var(--sub-letter-spacing);
  color: var(--color-body);
}