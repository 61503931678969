@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: 1.5s rotation linear infinite;
}
