.Toastify {
  &__toast {
    font-family: var(--font);
    font-size: var(--heading-6-font-size);
    line-height: var(--heading-6-line-height);
    letter-spacing: var(--heading-6-letter-spacing);
    color: var(--color-body);
    box-shadow: 0px 8px 10px -6px #0000001A;
    box-shadow: 0px 20px 25px -5px #0000001A;
    border-radius: var(--brand-border-radius);

    &--default {
      background: var(--brand-white--natural);
    }
  }

  &__progress-bar {
    background: var(--brand-blue);
  }

  &__close-button {
    &--default {
      color: var(--brand-black);
      opacity: 1;
    }
  }
}
