.simple-section {
  --background: var(--brand-white);
}

.simple-section {
  max-width: 400px;
  margin: 0 auto 24px;
  padding: 24px;
  background-color: var(--background);

  &--wide {
    max-width: 800px;
  }

  &__heading {
    margin-bottom: 16px;
  }
}
