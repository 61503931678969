.input {
  --background: var(--brand-light-grey);
  --font-size: var(--body-font-size);
  --color: var(--brand-midnight);
  --placeholder-color: var(--color-body-secondary);
  --border-radius: var(--brand-border-radius);
}

.input {
  display: inline-block;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: var(--background);
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--font-size);
  color: var(--color);
  border: none;
  border-radius: var(--border-radius);

  &--with-icon {
    padding-left: 48px;
  }

  &__wrapper {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    height: 18px;
    line-height: 18px;

    circle {
      stroke: var(--brand-natural-grey);
    }

    path {
      fill: var(--brand-natural-grey);
    }
  }
}

::placeholder {
  color: var(--placeholder-color);
  line-height: var(--font-size);
}
