.separator {
  --border-color: var(--brand-natural-grey);
}

.separator {
  width: 100%;
  border: 0;
  border-top: 1px solid var(--border-color);
  margin-top: 0;
}
