:root {
  --font: var(--brand-font);
  --body-font-size: 17px;
  --body-line-height: 24px;
  --body-letter-spacing: var(--brand-letter-spacing);
  --body-background: var(--brand-light-grey);

  --color-background: var(--brand-light-grey);
  --color-heading: var(--brand-midnight);
  --color-link: var(--brand-blue);
  --color-body: var(--brand-light-midnight);
  --color-body-secondary: var(--brand-grey);
  --color-error: var(--brand-red);

  --heading-1-font-size: 48px;
  --heading-1-line-height: 56px;
  --heading-1-letter-spacing: -1.6px;

  @media (min-width: 768px) {
    --heading-1-font-size: 84px;
    --heading-1-line-height: 96px;
    --heading-1-letter-spacing: -3px;
  }

  --heading-2-font-size: 40px;
  --heading-2-line-height: 48px;
  --heading-2-letter-spacing: -1.2px;

  @media (min-width: 768px) {
    --heading-2-font-size: 56px;
    --heading-2-line-height: 64px;
    --heading-2-letter-spacing: -1.8px;
  }

  --heading-3-font-size: 32px;
  --heading-3-line-height: 40px;
  --heading-3-letter-spacing: -0.9px;

  @media (min-width: 768px) {
    --heading-3-font-size: 40px;
    --heading-3-line-height: 48px;
    --heading-3-letter-spacing: -1.2px;
  }

  --heading-4-font-size: 25px;
  --heading-4-line-height: 32px;
  --heading-4-letter-spacing: -0.6px;

  @media (min-width: 768px) {
    --heading-4-font-size: 29px;
    --heading-4-line-height: 36px;
    --heading-4-letter-spacing: -0.75px;
  }

  --heading-5-font-size: 20px;
  --heading-5-line-height: 28px;
  --heading-5-letter-spacing: -0.4px;

  @media (min-width: 768px) {
    --heading-5-font-size: 22px;
    --heading-5-line-height: 28px;
    --heading-5-letter-spacing: -0.45px;
  }

  --heading-6-font-size: 15px;
  --heading-6-line-height: 20px;
  --heading-6-letter-spacing: -0.15px;

  --heading-7-font-size: 12px;
  --heading-7-line-height: 16px;

  --caption-font-size: 12px;
  --caption-line-height: 16px;

  --sub-font-size: 15px;
  --sub-line-height: 20px;
  --sub-letter-spacing: -0.15px;
}
