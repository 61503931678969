.popup-wrapper {
  --overlay-background: rgba(0, 0, 0, 0.7);
}

.popup {
  --background: var(--brand-white);
  --content-background: var(--brand-white);
  --border-color: var(--brand-light-grey);
}

.popup-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--overlay-background);

  @media (min-width: 640px) {
    padding: 40px;
  }
}

.popup {
  position: relative;
  z-index: 5;
  overflow: auto;
  width: 100%;
  background: var(--background);

  @media (min-width: 640px) {
    border-radius: var(--brand-border-radius--big);
  }

  $self: &;

  &--small {
    max-width: 385px;
    padding: 24px;

    #{$self}__heading {
      margin-bottom: 16px;
    }

    #{$self}__buttons {
      margin-top: 24px;
    }
  }

  &--big {
    max-width: 760px;

    #{$self}__header {
      padding: 48px 40px 24px;
      background: var(--content-background);
      border-bottom: 1px solid var(--border-color);

      @media (min-width: 640px) {
        padding: 32px 40px 16px;
      }
    }

    #{$self}__content {
      padding: 24px 40px 40px;
      background: var(--content-background);
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--first-left {
      > *:first-child {
        margin-right: auto !important;
      }
    }

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__close {
    display: block;
    margin-bottom: 30px;
    padding: 0;
    background: none;
    border: none;
    font-size: 0;
    cursor: pointer;
  }
}
