ol {
  counter-reset: item;
  padding-left: 0;

  > li {
      display: block;
      margin-bottom: 16px;

      span {
        display: block;
        margin-bottom: 16px;

        &:before {
          content: counters(item, ".") ". ";
          counter-increment: item
        }
      }
  }

  ol {
    padding-left: 8px;

    > li {
      position: relative;
      margin-bottom: 8px;
      padding-left: 25px;

      &:not(.no-numbering) {
        &:before {
          content: counters(item, ".") ". ";
          position: absolute;
          top: 0;
          left: 0;
          counter-increment: item
        }
      }

      &.no-numbering {
        span {
          &:before {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
