a {
  color: var(--color-link);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

p {
  margin: 0 0 32px;

  &:last-child {
    margin: 0;
  }
}

.heading-1 {
  font-size: var(--heading-1-font-size);
  line-height: var(--heading-1-line-height);
  letter-spacing: var(--heading-1-letter-spacing);
  font-weight: 700;
}

.heading-2 {
  font-size: var(--heading-2-font-size);
  line-height: var(--heading-2-line-height);
  letter-spacing: var(--heading-2-letter-spacing);
  font-weight: 700;
}

.heading-3 {
  font-size: var(--heading-3-font-size);
  line-height: var(--heading-3-line-height);
  letter-spacing: var(--heading-3-letter-spacing);
  font-weight: 700;
}

.heading-4 {
  font-size: var(--heading-4-font-size);
  line-height: var(--heading-4-line-height);
  letter-spacing: var(--heading-4-letter-spacing);
  font-weight: 700;
}

.heading-5 {
  font-size: var(--heading-5-font-size);
  line-height: var(--heading-5-line-height);
  letter-spacing: var(--heading-5-letter-spacing);
  font-weight: 700;
}

//heading 7 from design system
.heading-6 {
  font-size: var(--heading-6-font-size);
  line-height: var(--heading-6-line-height);
  letter-spacing: var(--heading-6-letter-spacing);
  font-weight: 700;
}

//heading 8 from design system
.heading-7 {
  font-size: var(--heading-7-font-size);
  line-height: var(--heading-7-line-height);
  font-weight: 700;
}

.caption {
  font-size: var(--caption-font-size);
  line-height: var(--caption-line-height);
  font-weight: 400;
}

.sub {
  font-size: var(--sub-font-size);
  line-height: var(--sub-line-height);
  letter-spacing: var(--sub-letter-spacing);
  color: var(--color-body-secondary);
}

.secondary {
  color: var(--color-body-secondary);
}

.red {
  color: var(--brand-red);
}
