.info-section {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  &__row {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__key {
    display: inline-block;
    width: 160px;
    margin-right: 24px;
  }
}
