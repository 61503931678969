.menu__language-button {
  span {
    line-height: 16px;
    padding: 0 5px;
  }
}

.language-selector {
  position: relative;

  .dropdown-menu {
    position: absolute;
    right: 0;
    bottom: 40px;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 1px 8px rgba(0, 0, 0, .1);
    padding: .375rem 0;
    border-radius: .25em;
    z-index: 4;
    font-size: 1rem;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s .2s, opacity .2s, -webkit-transform .2s ease-out;
    transition: visibility 0s .2s, opacity .2s, transform .2s ease-out;
    transition: visibility 0s .2s, opacity .2s, transform .2s ease-out, -webkit-transform .2s ease-out;

    &.hidden {
      display: none;
    }

    &:before {
      position: absolute;
      bottom: -14px;
      right: 45%;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 7px solid white;
      border-right: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid transparent;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li{
        position: relative;
        text-align: left;
        padding: 5px;
        a {
          color: var(--color-body);
          &:hover {
            text-decoration: none;
          }
        }
        &:hover {
          background-color: var(--color-background);
          cursor: pointer;
        }

        &.selected {
          &::before {
            position: absolute;
            right: 15px;
            top: 4px;
            height: 20px;
            width: 8px;
            content: "";
            transform: rotate(45deg);
            border-bottom: 2px solid var(--color-body-secondary);
            border-right: 2px solid var(--color-body-secondary);
          }
        }
      }
    }
  }
}
