.form-field {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  &__error,
  &__message {
    position: absolute;
    width: 100%;
    left: 4px;
    font-size: 14px;
    line-height: 14px;
  }

  &__error {
    color: var(--color-error);
  }

  &__message {
    color: var(--color-body-secondary);
  }

  &__show-hide {
    height: 24px;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
}
