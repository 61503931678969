.cancel-button {
  --font-size: var(--body-font-size);
  --line-height: var(--body-line-height);
  --letter-spacing: var(--body-letter-spacing);
  --color: var(--brand-red);
}

.cancel-button {
  display: block;
  padding: 12px;
  margin: 0 auto;
  font-family: var(--font);
  font-size: var(--font-size);
  font-weight: 700;
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  color: var(--color);
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
