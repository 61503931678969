.header {
  --background: var(--brand-white);
}

.header {
  background: var(--background);

  $self: &;

  &__main-content {
    padding: 24px 24px 16px;

    @media (min-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 174px;
      padding: 40px 40px 24px;
    }
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__mobile-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 8px;
    border-bottom: 1px solid var(--brand-light-grey);

    @media (min-width: 768px ) {
      padding: 16px;
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  &__mobile-section-logo {
    width: 130px;
  }

  &__mobile-menu-control {
    position: relative;
    z-index: 2;
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &__tabs {
    display: flex;
    justify-content: flex-start;
    margin: 24px 0 -24px;
    padding: 0;
    list-style-type: none;
  }

  &__tab {
    &:hover,
    &--active {
      #{$self}__tab-button {
        color: var(--color-heading);
      }
    }

    &--active {
      #{$self}__tab-button {
        box-shadow: 0px 2px 0px 0px var(--brand-blue);
      }
    }
  }

  &__tab-button {
    min-width: 200px;
    padding: 18px 40px;
    color: var(--color-body-secondary);
    font-size: var(--body-font-size);
    font-weight: 700;
    letter-spacing: -0.3px;
    text-align: center;
    background: none;
    border: none;
    cursor: pointer;
  }
}
