
.single-column-layout {
  --menu-color: var(--color-body-secondary)
}

.single-column-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 32px;
  background: var(--body-background);

  &__logo {
    width: 150px;
  }

  &__header {
    margin-bottom: 45px;
  }

  &__main {
    width: 100%;
  }

  &__link {
    display: block;
    text-align: center;
    margin: 0 0 24px;
  }

  &__footer {
    margin-top: auto;
  }

  &__footer-menu {
    margin: 0;
    padding-bottom: 1em;
    list-style-type: none;
    text-align: center;

    button {
      color:var(--color-body-secondary);
    }
  }

  &__footer-menu-li {
    color: var(--menu-color);
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.15px;
    text-decoration: none;
    background: none;
    border: none;
  }

  &__footer-menu-link {
    color: var(--menu-color);
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.15px;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
