body {
  margin: 0;
  background: var(--color-background);
  font-family: var(--font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  letter-spacing: var(--body-letter-spacing);
  color: var(--color-body);

  &.modal-open {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}
